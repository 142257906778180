import React, { useState } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import Scrollspy from "react-scrollspy";

const StyledScrollNavigation = styled.div`
  .scroll-navigation {
    ul {
      ${tw`md:space-y-4`}
      li {
        ${tw`font-medium flex items-center relative `}
        &.is-current {
          a {
            ${tw`text-primary-600`}
          }
          /* &:before {
            ${tw`w-full md:w-6`}
          } */
        }
        /* &:before {
          content: "";
          ${tw`h-px w-0 bg-primary-600 transition-all duration-300 ease-linear bottom-0 md:bottom-auto left-0 md:-left-9 absolute`}
        } */
        a {
          ${tw`block no-underline text-black/30`}
          &:hover {
            ${tw`text-primary-600`}
          }
        }
      }
    }
  }
`;

const ScrollNavigation = ({ textAlignment, className, children }) => {
  const [setActive, setActiveState] = useState("");

  const clickHandler = () => {
    setActiveState(setActive === "" ? "active" : "");
  };

  return (
    <StyledScrollNavigation
      className={`sticky top-18 md:top-40 ${setActive} ${className}`}
      onKeyDown={clickHandler}
      onClick={clickHandler}
    >
      <nav className="scroll-navigation md:sticky md:top-40">
        <Scrollspy
          items={[
            "section-1",
            "section-2",
            "section-3",
            "section-4",
            "section-5",
            "section-6",
            "section-7",
            "section-8",
            "section-9",
            "section-10",
          ]}
          offset={-100}
          currentClassName="is-current"
          className={`scrollspy flex w-full flex-row items-center justify-center space-x-6 md:flex-col md:space-x-0 ${
            textAlignment === "right" ? "md:items-end" : "md:items-start"
          }`}
        >
          {children}
        </Scrollspy>
      </nav>
    </StyledScrollNavigation>
  );
};

export default ScrollNavigation;
