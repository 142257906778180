import React from "react";
import { graphql, Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import { StaticImage } from "gatsby-plugin-image";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = {
    about: [
      {
        question: "Lorem ipsum?",
        answer: (
          <>
            <p className="mb-0"></p>
          </>
        ),
      },
      {
        question: "Lorem ipsum?",
        answer: (
          <>
            <p className="mb-0"></p>
          </>
        ),
      },
    ],
    houseHunting: [
      {
        question: "WHAT TYPES OF LOANS DO YOU OFFER?",
        answer: (
          <>
            <p>
            We offer a wide range of home loans for your refinance, including:
            </p>
            <ul className="list-disc pl-6">
              <li>Conventional</li>
              <li>FHA</li>
              <li>VA</li>
              <li>Jumbo</li>
              <li>Non-QM</li>
  
            </ul>
            
            
          </>
        ),
      },
      {
        question: "SHOULD I REFINANCE MY MORTGAGE?",
        answer: (
          <>
            <p>
            Refinancing your home is a good idea if it’ll save you money or allow you to pay off your mortgage faster. It’s also a great way to consolidate high-interest debt by using a cash-out refinance to tap into your home’s equity.
            </p>
           
          </>
        ),
      },
      {
        question: "WHAT ARE THE COSTS ASSOCIATED WITH REFINANCING?",
        answer: (
          <>
            <p>
            Generally, you can expect to pay anywhere from 2% to 6% of your loan amount. The cost will depend on several factors such as your lender, where you live, the state of your finances, the terms, etc. Some of the typical fees include:
            </p>
            <ul className="list-disc pl-6">
              <li>Application fee</li>
              <li>Origination fee</li>
              <li>Credit report fee</li>
              <li>Home appraisal</li>
              <li>Home inspection</li>
              <li>Title search and insurance fee</li>
              <li>Recording fee</li>
              <li>Reconveyance fee</li>
  
            </ul>
            
          </>
        ),
      },
      {
        question: "WHAT DO I NEED TO QUALIFY FOR A REFINANCE?",
        answer: (
          <>
            <p>
            The requirements will vary depending on your lender and the terms of the loan. You might NOT qualify if you:
            </p>
            <ul className="list-disc pl-6">
              <li>Have poor credit</li>
              <li>Have a high debt-to-income ratio</li>
              <li>Have a lien on your home</li>
              <li>Are underwater on your mortgage (you owe more than your home is worth)
  </li>
  
            </ul>
            
          </>
        ),
      },
      
      {
        question: "HOW LONG DOES IT TAKE TO REFINANCE?",
        answer: (
          <>
            <p>
            Refinancing your home typically takes between 30 to 45 days to complete. It’s important to note, however, that any of the services provided by third parties (such as appraisals or inspections) may delay the process.
            </p>
           
          </>
        ),
      },
      {
        question: "WHAT’S THE DIFFERENCE BETWEEN INTEREST RATE AND APR?",
        answer: (
          <>
            <p>
            An interest rate is the percentage of your total loan balance, paid on a monthly basis, that you pay a lender in exchange for borrowing money from them.
            </p>
            <p>APR (annual percentage rate) is the yearly interest rate that takes into account all of the other charges or fees of your loan (such as mortgage insurance, closing costs, and loan origination fees). APR is a tool you can use for an apples-to-apples comparison of various loans.</p>
           
          </>
        ),
      },
      {
        question: "DO YOU OFFER RATE LOCKS?",
        answer: (
          <>
            <p>
            Yes, we do! Locking in a mortgage rate can protect you from fluctuations in the market and we’re happy to do that for you.
            </p>
           
          </>
        ),
      },
    ],
    homeLoan: [
      {
        question: "WHAT CAN I BUY WITH A CONVENTIONAL HOME LOAN?",
        answer: (
          <>
            <p className="mb-0">
              You can purchase a single-family home, condo, or townhome with a
              conventional mortgage through a private lender. The property can be
              your primary residence, a second home, or an investment property
              (such as a rental).
            </p>
          </>
        ),
      },
      {
        question: "HOW ARE INTEREST RATES DETERMINED?",
        answer: (
          <>
            <p className="mb-0">
              For lenders, it’s all about how much risk you pose to them as a
              borrower. When determining your interest rate, they’ll typically
              look at your credit score, the amount of debt you have, and how much
              of a down payment you plan to make. They’ll also take into
              consideration what the current market reflects.
            </p>
          </>
        ),
      },
      {
        question: "WHAT TYPE OF DOCUMENTATION WILL I NEED TO PROVIDE?",
        answer: (
          <>
            <p>
              For a conventional mortgage, lenders will typically want to see:
            </p>
            <ul className="list-disc pl-6">
              <li>A copy of your driver’s license</li>
              <li>
                Two years of W2s (or 2 years of full tax returns if you’re
                self-employed
              </li>
              <li>2 recent pay stubs</li>
              <li>
                A copy of your mortgage statement (if you’re currently paying on a
                home loan)
              </li>
            </ul>
          </>
        ),
      },
    ],
    refinancing: [
      {
        question: "WHAT IS AN FHA LOAN?",
        answer: (
          <>
            <p className="mb-0">
            An FHA loan is one that’s insured by the Federal Housing Administration (FHA). The FHA doesn’t lend you the money, you get it from a bank or private lender. The FHA simply insures the loan to protect lenders against losses, such as a property owner defaulting on their mortgage.
            </p>
          </>
        ),
      },
      {
        question: "HOW DO FHA HOME LOANS WORK?",
        answer: (
          <>
            <p className="mb-0">
            FHA loans are very similar to conventional loans. You’ll apply for a loan with a bank or lender that’s FHA-approved (many are) and will go through the same process to obtain a loan. The difference is that it’s typically easier to qualify for an FHA loan than it is for a conventional loan.
            </p>
          </>
        ),
      },
      {
        question: "WHAT ARE THE DIFFERENT TYPES OF FHA LOANS?",
        answer: (
          <>
            <p>
            Many home buyers get a traditional FHA loan, but there are other FHA loan types that might better fit your situation:
            </p>
            <ul className="list-disc pl-6">
              <li>Home Improvement Loan</li>
              <li>Graduated Payment Mortgage</li>
              <li>Reverse Mortgage</li>
              <li>Energy Efficient Mortgage </li>
            </ul>
          </>
        ),
      },
      {
        question: "FHA vs. CONVENTIONAL: WHAT’S THE DIFFERENCE?",
        answer: (
          <>
            <p className="mb-0">
            FHA mortgages are backed by the government (the Federal Housing Administration), while conventional loans are not. FHA loans have more relaxed credit and financial requirements than conventional loans, making them a popular option among home buyers.
            </p>
          </>
        ),
      },
      {
        question: "WHAT ARE THE FHA LOAN REQUIREMENTS?",
        answer: (
          <>
            <p className="mb-0">
            Aside from the credit score, down payment, and income requirements we’ve listed previously on this page, FHA loans also require that you:
            </p>
            <ul className="list-disc pl-6">
              <li>Get an appraisal by an FHA-approved appraiser</li>
              <li>Get an inspection of the property</li>
              <li>Plan to live in the home as your primary residence</li>
              <li>Move in within 60 days of closing</li>
            </ul>
          </>
        ),
      },
      {
        question: "WHAT ARE THE ADVANTAGES OF AN FHA LOAN?",
        answer: (
          <>
            <p className="mb-0">
            There are many benefits, including:
            </p>
            <ul className="list-disc pl-6">
              <li>Low down payment</li>
              <li>Flexible credit score and debt-to-income (DTI) requirements</li>
              <li>Low interest rates</li>
              <li>Sellers can contribute to closing costs</li>
              <li>Gift funds are allowed for the down payment</li>
              <li>Just about every lender offers FHA loans</li>
            </ul>
          </>
        ),
      },
      {
        question: "WHAT ARE THE DOWNSIDES OF AN FHA LOAN?",
        answer: (
          <>
            <p className="mb-0">
            Like any type of loan, FHA loans do have some disadvantages, including:
            </p>
            <ul className="list-disc pl-6">
              <li>Mortgage insurance is required</li>
              <li>There are FHA loan limits for each county in the U.S.</li>
              <li>They may not be as attractive to sellers (because of the inspection standards that may result in the seller having to complete repairs prior to closing)</li>
              <li>You can’t buy a vacation home or investment property with an FHA loan</li>
            </ul>
          </>
        ),
      },
      {
        question: "CAN I GET AN FHA LOAN IF I HAVE STUDENT LOAN DEBT?",
        answer: (
          <>
            <p className="mb-0">
            Yes, you can. Your student loan debt is, however, included in your monthly debts, and if it pushes your debt-to-income ratio too high, you might not qualify for a mortgage.
            </p>
          </>
        ),
      },
      {
        question: "IS THERE MORTGAGE INSURANCE ON AN FHA LOAN?",
        answer: (
          <>
            <p className="mb-0">
            Yes, you are required to have mortgage insurance (unless you put down 20 percent or more on your home purchase).
            </p>
          </>
        ),
      },
      {
        question: "IS IT EASY TO GET AN FHA LOAN?",
        answer: (
          <>
            <p className="mb-0">
            FHA loans are one of the most popular types of loans because they’re easier to qualify for than conventional loans. Even those with blemished credit or low scores can qualify.
            </p>
          </>
        ),
      },
    ],
    jumboLoans: [
      {
        question: "WHAT IS A JUMBO LOAN?",
        answer: (
          <>
            <p>
            A jumbo loan can help you purchase a home that exceeds the conforming loan limits set by Fannie Mae and Freddie Mac. As of 2022, the limit is $647,200 for most of the U.S., apart from Alaska, Hawaii, Guam, and the U.S. Virgin Islands, where the limit is $970,800. 
            </p>
            <p className="mb-0">A jumbo loan can be used to purchase a primary residence, an investment property, or a vacation home. Rates tend to be a bit higher because lenders generally have a higher risk.</p>
          </>
        ),
      },
      {
        question: "WHY CONSIDER A JUMBO LOAN?",
        answer: (
          <>
            <p className="mb-0">
            A jumbo loan can help you purchase a home that exceeds conforming loan limits, such as those in a highly competitive market, or in a desirable area. 
            </p>
            <p className="mb-0">
            Financing is available for up to $2.5 million and jumbo loans offer the convenience of one loan vs. multiple loans.
            </p>
          </>
        ),
      },
      {
        question: "HOW DO I QUALIFY FOR A JUMBO LOAN?",
        answer: (
          <>
            <p>
            To qualify, you’ll need to be a high earner, have a great credit score, and have a low debt-to-income ratio. 
            </p>
            
          </>
        ),
      },
      {
        question: "WHAT’S THE DIFFERENCE BETWEEN A CONFORMING LOAN AND A JUMBO LOAN?",
        answer: (
          <>
          <p>A conforming loan meets the loan limit set by Fannie Mae and Freddie Mac, which is $647,200 for most of the U.S. (the limit is $970,800 in Alaska, Hawaii, Guam, and the U.S. Virgin Islands).</p>
            <p className="mb-0">
            A jumbo loan exceeds those limits and is therefore considered non-conforming.
            </p>
            
          </>
        ),
      },
      {
        question: "HOW DO JUMBO LOAN RATES COMPARE TO CONFORMING RATES?",
        answer: (
          <>
            <p>
            Because lenders are taking on a bigger risk, you can expect a higher interest rate. It ultimately depends on the market conditions, plus your lender.
            </p>
            
          </>
        ),
      },
      {
        question: "HOW DO I GET A CERTIFICATE OF ELIGIBILITY?",
        answer: (
          <>
            <p>
            Visit the U.S. Department of Veterans Affairs Certificate of Eligibility page for instructions on requesting a COE.
            </p>
            
          </>
        ),
      },
      {
        question: "CAN MY LENDER GET MY CERTIFICATE OF ELIGIBILITY FOR ME?",
        answer: (
          <>
            <p>
            Yes, you can ask your lender to obtain your Certificate of Eligibility on your behalf.
            </p>
            
          </>
        ),
      },
      {
        question: "WHAT ARE THE LIMITS OF A JUMBO LOAN?",
        answer: (
          <>
            <p>
            You can finance up to $2.5 million, as long as you qualify.
            </p>
          </>
        ),
      },
      {
        question: "HOW DOES A JUMBO LOAN WORK?",
        answer: (
          <>
            <p>
            Here’s how the process works:
            </p>
            <ul className="list-disc pl-6">
            <li>Complete our simple Jumbo Loan Qualifier</li>
            <li>We’ll give you loan options based on your criteria and scenario</li>
            <li>We’ll help you compare mortgage interest rates and terms</li>
            <li>You’ll choose the offer that best fits your needs</li>
  
            </ul>
          </>
        ),
      },
    ],
    nonQM: [
      {
        question: "WHAT ARE NON-QM LOANS?",
        answer: (
          <>
            <p>
            A non-QM loan, or non-qualified mortgage, is one where alternative methods are used to verify income. For example, you can provide bank statements to a lender or use your assets to show that you have the ability to repay a loan. 
            </p>
            
          </>
        ),
      },
      {
        question: "WHAT IS A QUALIFIED MORTGAGE?",
        answer: (
          <>
            <p>
            A qualified mortgage is one that meets the standards outlined in the Dodd-Frank Wall Street Reform and Consumer Protection Act (regulation that took effect in 2010 following the financial crisis of 2007-2010).
            </p>
            <p className="mb-0">
            Qualified mortgages do not permit risky loan features, such as interest-only loans, balloon payments, and negative amortization.
            </p>
          </>
        ),
      },
      {
        question: "WHAT ARE THE BENEFITS OF NON-QM LOANS?",
        answer: (
          <>
            <p>
            The biggest benefit is that non-traditional borrowers have an opportunity to purchase a home. Borrowers can also show proof of income in different ways, such as by providing bank statements. And you can even qualify for a non-QM loan just one day after completing a bankruptcy or foreclosure.
            </p>
            
          </>
        ),
      },
      
      {
        question: "WHO MIGHT QUALIFY FOR A NON-QM LOAN?",
        answer: (
          <>
            <p>
            They’re designed for the following types of buyers:
            </p>
            <ul className="list-disc pl-6">
                <li>Self-employed</li>
                <li>Variable income</li>
                <li>Assets, but no income</li>
                <li>Bad credit</li>
                <li>Bankruptcy</li>
                <li>Foreclosure</li>
            </ul>
          </>
        ),
      },
    ],

    DCSR: [
      {
        question: "WHAT IS DSCR?",
        answer: (
          <>
            <p>
            DSCR stands for Debt Service Coverage Ratio, which is a comparison of a property’s cash flow to its debt obligations. Lenders will look at this ratio to see whether the property you want to purchase has enough positive cash flow to cover the loan.
            </p>
            
          </>
        ),
      },
      {
        question: "WHAT IS A GOOD DSCR RATIO?",
        answer: (
          <>
            <p>
            Lenders typically want to see a debt service coverage ratio between 1.25 to 1.50, which indicates there will be enough net operating income to cover the loan.
            </p>
            <p className="mb-0">
            For reference, a DSCR of 1.0 means there is just enough cash flow to cover the loan and that you’ll be breaking even. This is a vulnerable position to be in, and lenders are wary that the mortgage might not get paid if cash flow dips. A DSCR of 1.25 to 1.50 means there’s enough cash flow to cover the loan, plus some additional buffer, which makes lenders much more comfortable.
            </p>
          </>
        ),
      },
      {
        question: "WHY DOES DSCR MATTER TO INVESTORS?",
        answer: (
          <>
            <p>
            When you apply for a home loan, lenders will look at your personal income and compare it to your expenses. The calculation tells a lender whether you have enough money in your budget for a monthly mortgage payment.</p>
            
  
            <p>
  This can be problematic if you're an investor, especially if you have multiple properties in your portfolio. Your monthly debt payments could very well be more than your personal income, automatically disqualifying you from obtaining a loan.</p>
            
  
  <p>
  That's why DSCR, where lenders instead look at cash flow and personal income is taken out of the equation, is great for investors.
  
            </p>
            
          </>
        ),
      },
      
      {
        question: "WHAT ARE THE PROS AND CONS OF A DSCR LOAN?",
        answer: (
          <>
            <p>
            As with any type of home loan, it’s important to understand both the pros and cons.
            </p>
            <p className="text-bold">Pros</p>
            <ul className="list-disc pl-6">
                <li>Your personal income is not a factor in qualifying</li>
                <li>Faster application and closing</li>
                <li>Easy to scale because there’s no limit on properties
                </li>
            </ul>
            <p className="text-bold">Cons</p>
            <ul className="list-disc pl-6">
                <li>A hefty down payment of 20% to 25%</li>
                <li>May have a higher interest rate than a traditional mortgage</li>
                <li>Not designed for large investments over $5 million
                </li>
            </ul>
          </>
        ),
      },
    ],

    exchange: [
      {
        question: "WHAT IS A 1031 LOAN, OR 1031 EXCHANGE?",
        answer: (
          <>
            <p>
            A 1031 exchange refers to the sale of one investment property and the purchase of another. When you follow the IRS rules for the swap, you can defer taxes on capital gains
            </p>
            
          </>
        ),
      },
      {
        question: "HOW DOES A 1031 EXCHANGE WORK?",
        answer: (
          <>
            <p>
            Both investments have to be “like-kind” (similar in nature). For example, real estate such as houses, buildings, vacant lots, etc., are like-kind investments that you can exchange. Your new acquisition must also be of equal or greater value than your existing investment.
            </p>
            <p className="text-bold">How Exchanges Are Structured</p>
            <p>Depending on your situation, there are four ways to structure an exchange:</p>
            <ul className="list-disc pl-6">
                <li>Simultaneous Exchange: Buying and selling at, or very close to, the same time</li>
                <li>Delayed Exchange: Buying and selling within 180 days</li>
                <li>Reverse Exchange: Buy first, sell later</li>
                <li>Construction or Improvement Exchange: Typically used when vacant, underdeveloped land is acquired
  
                </li>
            </ul>
          </>
        ),
      },
      {
        question: "WHAT IS THE BENEFIT OF A 1031 EXCHANGE VS. SELLING A PROPERTY?",
        answer: (
          <>
            <p>
            When you sell an investment property you’re required to pay a capital gains tax, which eats into your sale profit. </p>
  
            <p>The benefit of a 1031 exchange is that it allows you to sell an investment property, buy another one and defer the capital gains tax. The tax would only be due when you sell the property.</p>
  
            <p>The result is that you get to keep more of the sale profit, which you’re required to reinvest in order to gain the advantage of the deferred capital gains tax.
  </p>
            
          </>
        ),
      },
    ],

    selfEmployed: [
      {
        question: "ARE THERE DIFFERENT STANDARDS FOR SELF-EMPLOYED BORROWERS?",
        answer: (
          <>
            <p>
            Because self-employed borrowers don’t have a W-2, there is more documentation required to prove income and verify the business. However, the rest of the loan process, and standards, are the same as they would be for borrowers who work for employers.
            </p>
            
          </>
        ),
      },
      {
        question: "WILL I PAY A HIGHER INTEREST RATE BECAUSE I’M SELF-EMPLOYED?",
        answer: (
          <>
            <p>
            Lenders are taking on added risk when loaning money to someone self-employed who seeks a mortgage and may increase the interest rate to account for that.
            </p>
           
          </>
        ),
      },
      {
        question: "HOW CAN I PREPARE BEFORE APPLYING FOR A LOAN?",
        answer: (
          <>
            <p>
            To become attractive to lenders, you’ll want to:
            </p>
            <ul className="list-disc pl-6">
              <li>Check your credit report and resolve any discrepancies</li>
              <li>Improve your debt-to-income ratio by increasing your income and/or reducing your debt</li>
              <li>Save up a large down payment</li>
              <li>Put significant cash reserves in the bank</li>
              <li>Make sure you have all the required documentation</li>
            </ul>
            
          </>
        ),
      },
    ],
  };

  return (
    <Layout>
      <SearchEngineOptimization
        title="FAQs | Home Loans | Accel Mortgage"
        description="Get answers to frequently asked questions about Accel’s mortgages and home loans. Read more about how we can help."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-20 overflow-hidden pt-16 pb-20 md:mb-32 md:pt-22 md:pb-28">
        <div className="container">
          <div className="absolute left-0 top-0 h-full w-full">
            <StaticImage
              src="../images/15.0 FAQ/background.jpg"
              loading="eager"
              className="h-full"
            />
          </div>

          <div className="relative mx-auto max-w-[748px] text-center">
            <h1>FAQs</h1>
            <p className="mb-0">
              Confused about home loans? There’s no need to be—we’ve got answers
              to the questions we get the most. And if you don’t see what you’re
              looking for here, please reach out.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-16">
            <div className="space-y-20 md:col-span-8 md:col-start-1 md:space-y-32">
              <div id="section-1">
                <header className="mb-8 md:mb-10">
                  <h2>Refinancing Your Home Loan</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-700/20"
                >
                  {faqs.houseHunting.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-700/20 py-6 md:py-8"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="heading-five mb-0">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } text-base text-gray-700/50`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-2">
                <header className="mb-8 md:mb-10">
                  <h2>Conventional Home Loan</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-700/20"
                >
                  {faqs.homeLoan.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-700/20 py-6 md:py-8"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="heading-five mb-0">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } text-base text-gray-700/50`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-3">
                <header className="mb-8 md:mb-10">
                  <h2>FHA Loan</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-700/20"
                >
                  {faqs.refinancing.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-700/20 py-6 md:py-8"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="heading-five mb-0">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } text-base text-gray-700/50`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-4">
                <header className="mb-8 md:mb-10">
                  <h2>Jumbo Loan</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-700/20"
                >
                  {faqs.jumboLoans.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-700/20 py-6 md:py-8"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="heading-five mb-0">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } text-base text-gray-700/50`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-5">
                <header className="mb-8 md:mb-10">
                  <h2>Non-QM Loan</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-700/20"
                >
                  {faqs.nonQM.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-700/20 py-6 md:py-8"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="heading-five mb-0">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } text-base text-gray-700/50`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-6">
                <header className="mb-8 md:mb-10">
                  <h2>DSCR: Home Loans for Investors</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-700/20"
                >
                  {faqs.DCSR.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-700/20 py-6 md:py-8"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="heading-five mb-0">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } text-base text-gray-700/50`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-7">
                <header className="mb-8 md:mb-10">
                  <h2>1031 Exchange Loan</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-700/20"
                >
                  {faqs.exchange.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-700/20 py-6 md:py-8"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="heading-five mb-0">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } text-base text-gray-700/50`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>

              <div id="section-8">
                <header className="mb-8 md:mb-10">
                  <h2>Home Loans for the Self-Employed</h2>
                </header>
                <Accordion
                  allowZeroExpanded={true}
                  className="border-t border-gray-700/20"
                >
                  {faqs.selfEmployed.map((faq, i) => {
                    return (
                      <div key={i}>
                        <AccordionItem
                          uuid={i}
                          className="border-b border-gray-700/20 py-6 md:py-8"
                        >
                          <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                            <AccordionItemState>
                              {(state) => {
                                return (
                                  <>
                                    <p className="heading-five mb-0">
                                      {faq.question}
                                    </p>

                                    <i
                                      className={`far ${
                                        state.expanded
                                          ? "fa-chevron-up"
                                          : "fa-chevron-down"
                                      } text-base text-gray-700/50`}
                                    ></i>
                                  </>
                                );
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                          <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                            {faq.answer}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    );
                  })}
                </Accordion>
              </div>
            </div>

            <ScrollNavigation
              className="hidden md:col-span-4 md:col-end-13 md:block"
              textAlignment="right"
            >
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Refinancing Your Home Loan"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Conventional Home Loan"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="FHA Loan"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-4"}
                  title="Jumbo Loan"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-5"}
                  title="Non-QM Loan"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-6"}
                  title="DSCR: Home Loans for Investors"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-7"}
                  title="1031 Exchange Loan"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-8"}
                  title="Home Loans for the Self-Employed"
                  stripHash
                />
              </li>
            </ScrollNavigation>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
