import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";

import decorative from "../../images/0.0 Repeating Modules/CTA/decorative.svg";
import decorativeLine from "../../images/0.0 Repeating Modules/CTA/decorative line.svg";

const CTA = ({
  heading,
  headingLevel,

  className,
}) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`relative py-16 ${className}`}>
      <div className="absolute inset-0 hidden h-full w-full md:block">
        <StaticImage
          src="../../images/0.0 Repeating Modules/CTA/Background.jpg"
          loading="lazy"
          class="h-full"
        />
      </div>

      <div className="absolute inset-0 h-full w-full md:hidden">
        <StaticImage
          src="../../images/0.0 Repeating Modules/CTA/Background mobile.jpg"
          loading="lazy"
          class="h-full"
        />
      </div>
      <div className="container relative z-10">
        <img
          src={decorativeLine}
          className="absolute -top-28 -right-28 z-10 md:-top-20 md:right-20"
        />

        <div className="z-20 items-end justify-between md:flex md:space-x-10 lg:space-x-20">
          <div>
            <HeadingTag className="mb-4 text-white">
              {heading || ["Your First Step Starts Here"]}
            </HeadingTag>
            <p className="text-primary-50 md:mb-0">
              Whether you’re a first-time homebuyer, are interested in
              refinancing, or are over 62 and looking to do a reverse mortgage,
              we’re a Pasadena mortgage broker that can help you reach your
              goal.
            </p>
          </div>
          <div className="relative inline-grid items-center gap-y-4 md:flex md:gap-y-0 md:space-x-4">
            <ButtonSolid
              href="/request-rates/"
              text="Get a Rate Quote"
              className="z-20"
            />
            <ButtonGhost
              href="https://markdarling.floify.com/apply-now"
              text="Get Pre-Approved"
              outboundLink={true}
              altStyle={2}
              className="z-20"
            />

            <img
              src={decorative}
              className="absolute -bottom-8 -right-8 w-[117px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
